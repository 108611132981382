<template>
  <div
    class="header-block"
    :class="{ withoutCTA }"
  >
    <image-with-gradient-overlay
      :image-url="backgroundImageUrl"
      class="image-container"
      data-testid="headerBlockImage"
    >
      <div class="book-cover-wrapper">
        <img
          v-if="bookCover"
          data-testid="coverImage"
          :src="bookCover.url"
          :alt="bookCover.alt"
          class="book-cover"
        >
      </div>
    </image-with-gradient-overlay>

    <b-row class="content-row">
      <b-col class="content-column">
        <div class="content">
          <h1
            class="h1"
            data-testid="headerBlockTitle"
            v-html="title"
          />

          <p
            v-if="description"
            class="description b1"
            data-testid="headerBlockDescription"
          >
            {{ description }}
          </p>

          <div class="actions">
            <custom-button
              v-if="type === 'Category' && booksPath"
              primary
              label="View Books"
              class="action-btn"
              :to="booksPath"
              data-testid="headerBlockViewBookButton"
            />

            <custom-button
              v-if="type === 'Category' && articlesPath"
              primary
              label="View Articles"
              class="action-btn"
              :to="articlesPath"
              data-testid="headerBlockViewArticleButton"
            />

            <custom-button
              v-if="type === 'Collection' && collectionPath"
              primary
              label="Explore Collection"
              class="action-btn"
              :to="collectionPath"
              data-testid="headerBlockViewCollectionButton"
            />

            <custom-button
              v-if="type === 'Book' && bookPath"
              primary
              label="Explore Book"
              class="action-btn"
              :to="bookPath"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import CustomButton from '../atoms/CustomButton'
import ImageWithGradientOverlay from '../molecules/ImageWithGradientOverlay'

export default {
  name: 'HeaderBlock',
  components: {
    BRow,
    BCol,
    CustomButton,
    ImageWithGradientOverlay
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: function (type) {
        return ['Category', 'Collection', 'Book'].indexOf(type) !== -1
      }
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    backgroundImageUrl: {
      type: String,
      required: false,
      default: null
    },
    booksPath: {
      type: String,
      required: false,
      default: null
    },
    articlesPath: {
      type: String,
      required: false,
      default: null
    },
    collectionPath: {
      type: String,
      required: false,
      default: null
    },
    bookPath: {
      type: String,
      required: false,
      default: null
    },
    bookCover: {
      type: Object,
      required: false,
      default: null,
      validator: function (bookCover) {
        return ['url', 'alt'].every(key => key in bookCover)
      }
    },
    withoutCTA: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.header-block {
  position: relative;

  .content-column {
    display: flex;
    align-items: center;
  }

  .content-row {
    color: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px;
      padding-right: 0;
      max-width: 60%;

      h1 {
        margin-bottom: 26px;
      }

      .description {
        margin-bottom: 36px;
      }

      .actions {
        display: flex;

        .action-btn {
          margin-right: 16px;
        }
      }
    }
  }

  &.book {
    .image-container .book-cover-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 40px;

      .book-cover {
        width: 225px;
      }
    }

    .content-row .content {
      height: calc(225px / 0.8);
      padding-top: 0;
      padding-left: 305px;
      justify-content: flex-start;
      position: relative;
      bottom: 8px;
    }
  }

  &.withoutCTA {
    .content-row .content {
      h1 {
        margin-top: -10px;
        margin-bottom: 20px;
      }

      .description {
        margin-bottom: -5px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-md-max) {
    .content-row {
      .content {
        padding: 40px 0 40px 32px;

        h1 {
          margin-bottom: 10px;
        }
      }

      .description {
        margin-bottom: 28px;
      }
    }

    &.book {
      .image-container .book-cover-wrapper {
        padding-left: 32px;

        .book-cover {
          width: 187.5px;
        }
      }

      .content-row .content {
        height: calc(187.5px / 0.8);
        padding-left: 251.5px;
        bottom: 7px;
      }
    }

    &.withoutCTA {
      .content-row .content {
        h1 {
          margin-top: -8px;
        }

        .description {
          margin-bottom: -6px;
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-sm-max) {
    .content-row {
      position: static;
      color: $black;

      .content {
        padding: 0 24px;
        border: 1px solid $grey-8;
        border-top-width: 0;
        max-width: none;

        h1 {
          margin: 28px 0 21px;
        }

        .description {
          margin: 0 0 28px;
        }

        .actions {
          flex-direction: column;
          margin-bottom: 32px;

          .action-btn {
            width: 100%;
            margin-right: 0;
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.book {
      .image-container .book-cover-wrapper {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .book-cover {
          width: 156.25px;
        }
      }

      .content-row .content {
        padding-left: 24px;
        padding-top: 25px;
        bottom: 0;
        width: 100%;
        height: auto;

        h1 {
          display: block;
        }
      }
    }

    &.withoutCTA {
      .content-row .content {
        border: none;
        padding: 0;

        h1 {
          margin: 23px 0 18px;
        }
      }
    }
  }
}
</style>

<template>
  <b-row class="image-with-gradient-overlay">
    <b-col class="outer-container">
      <div
        class="inner-container"
        :class="[
          {'no-bg-img': !imageUrl},
          {'expand-on-mobile': expandOnMobile}
        ]"
        :style="imageUrl && `background-image: url(${imageUrl});`"
        data-testid="imageWithGradientBackground"
      >
        <div class="content">
          <slot />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'ImageWithGradientOverlay',
  components: {
    BRow,
    BCol
  },
  props: {
    imageUrl: {
      type: String,
      required: false,
      default: null
    },
    expandOnMobile: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.image-with-gradient-overlay {
  .outer-container .inner-container {
    padding-top: 50%;
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;

    .content,
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .content {
      color: $white;
    }

    &::before {
      content: '';
      background:
        linear-gradient(
          to right,
          $black,
          rgba($black, 0.4) 60%,
          rgba($black, 0.15)
        );
    }

    &.no-bg-img {
      .content {
        background-color: $grey-1;
      }

      &::before {
        content: none;
      }
    }

    @media only screen and (max-width: $breakpoint-sm-max) {
      &:not(.expand-on-mobile)::before {
        content: none;
      }

      &.expand-on-mobile {
        padding-top: 0;

        .content {
          position: relative;
          z-index: 2;
        }

        &::before {
          background:
            linear-gradient(
              to bottom,
              transparent,
              32px,
              rgba($black, 0.25),
              161.5px,
              rgba($black, 0.5),
              365px,
              $black
            );
        }
      }
    }
  }
}
</style>
